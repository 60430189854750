@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .card {
        @apply rounded-xl shadow-lg;
    }
    .required::after {
        content: ' *';
        color: red;
      }
}